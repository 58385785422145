<ng-container
    *transloco="let t; prefix: 'stAdvantageCalculator'"
    [formGroup]="form">
    <div class="mb-6">
        <big-service-tool-advantage-calculator-form
            [group]="form.controls.primaryInsurant"
            [metadata]="metadata"
            [insurantIndex]="1"
            [hasBeenSubmitted]="hasBeenSubmitted" />

        <div class="mt-10">
            <big-ui-button
                [buttonStyle]="'primary'"
                class="block"
                [type]="'button'"
                (clicked)="emitEvent('submitted', $event)">
                <span class="flex items-center">
                    <big-icon
                        [icon]="iconPlus"
                        class="mr-2 fill-white"></big-icon>
                    <span class="translate-y-[1px]">{{ t('label.addInsurant') }}</span>
                </span>
            </big-ui-button>
        </div>

        <div class="mt-20 text-center">
            <big-ui-button
                [buttonStyle]="'secondary'"
                class="mt-10"
                [type]="'submit'"
                (clicked)="emitEvent('summary', $event)">
                {{ t('label.submit') }}
            </big-ui-button>
        </div>
    </div>
</ng-container>
