<ng-container
    [formGroup]="group"
    *transloco="let t; prefix: 'stAdvantageCalculator'">
    <fieldset>
        <legend>
            <h4>{{ t(insurantType === 'additional' ? 'title.additionalInsurants' : 'title.yourData') }}</h4>
        </legend>
        @if (insurantType === 'additional') {
            <big-ui-form-row
                #nameFormRow
                [id]="'name'"
                [label]="t('label.additionalInsurants.name')"
                [isRequired]="true"
                [errors]="group.controls.name?.errors"
                [showError]="hasBeenSubmitted"
                [errorOverrides]="nameErrorOverrides">
                <big-ui-input
                    [isValid]="!!group.controls.name?.valid"
                    [showValidation]="hasBeenSubmitted"
                    [isRequired]="true"
                    [id]="'name'"
                    formControlName="name"
                    name="name"
                    [bigStFieldTracking]="{
                        metadata,
                        fieldName: nameFormRow.label!,
                        valid: !hasBeenSubmitted || group.controls.name.valid,
                        insuredPerson: insurantIndex,
                    }" />
            </big-ui-form-row>
        }

        <big-ui-form-row
            #genderFormRow
            [id]="'gender'"
            [label]="t(insurantType === 'additional' ? 'label.additionalInsurants.gender' : 'label.gender')"
            [isRequired]="true"
            [errors]="group.controls.gender.errors"
            [showError]="hasBeenSubmitted"
            [errorOverrides]="genderErrorOverrides">
            <big-ui-button-group
                [id]="'gender'"
                class="w-full"
                [isValid]="group.controls.gender.valid"
                [isTouched]="group.controls.gender.touched"
                [options]="genderOptions"
                [showValidation]="hasBeenSubmitted"
                [isRequired]="true"
                radiogroupId="gender"
                formControlName="gender"
                name="gender"
                [bigStFieldTracking]="{
                    metadata,
                    fieldName: genderFormRow.label!,
                    valid: !hasBeenSubmitted || group.controls.gender.valid,
                    insuredPerson: insurantIndex,
                }" />
        </big-ui-form-row>

        <big-ui-form-row
            #ageFormRow
            [id]="'age'"
            [label]="t(insurantType === 'additional' ? 'label.additionalInsurants.age' : 'label.age')"
            [isRequired]="true"
            [errors]="group.controls.age.errors"
            [showError]="hasBeenSubmitted"
            [errorOverrides]="ageErrorOverrides">
            <big-ui-input
                [isValid]="group.controls.age.valid"
                [showValidation]="hasBeenSubmitted"
                [isRequired]="true"
                [id]="'age'"
                formControlName="age"
                name="age"
                [bigStFieldTracking]="{
                    metadata,
                    fieldName: ageFormRow.label!,
                    valid: !hasBeenSubmitted || group.controls.age.valid,
                    insuredPerson: insurantIndex,
                }" />
        </big-ui-form-row>
    </fieldset>
</ng-container>
