@if (form) {
    <form
        [formGroup]="form">
        @if (formStep === 'primaryInsurant') {
            <big-service-tool-advantage-calculator-primary-insurant-step
                [metadata]="metadata"
                [hasBeenSubmitted]="hasBeenSubmitted"
                (submitted)="onPrimaryInsurantSubmit()"
                (summaryClicked)="onPrimaryInsurantSummaryClicked()" />
        } @else if (formStep === 'additionalInsurants') {
            <big-service-tool-advantage-calculator-additional-insurants-step
                [metadata]="metadata"
                (backClicked)="onAdditionalInsurantBackClick()"
                (summaryClicked)="onAdditionalInsurantSummaryClicked()" />
        } @else if (formStep === 'summary') {
            <big-service-tool-advantage-calculator-summary-step
                [metadata]="metadata"
                (recalculateAdvantagesClicked)="onRecalculateAdvantagesClick()" />
        }
    </form>
}
