/* eslint-disable no-null/no-null, @typescript-eslint/unbound-method */
import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AdvantageCalculatorForm, AdvantageCalculatorResultItemFormElement, InsurantGroup } from '../models/advantage-calculator-form.model';
import { AdvantageCalculatorResultItem, advantageConditions } from '../models/advantage-calculator-result-item.model';

@Injectable({ providedIn: 'any' })
export class AdvantageCalculatorService {
    private readonly formBuilder = inject(FormBuilder);
    private readonly destroyRef = inject(DestroyRef);
    private readonly ageValidatorFn = Validators.pattern('^(100|[1-9]?[0-9])$');
    private readonly nameValidatorFn = Validators.pattern('^[^0-9]+$');

    private form!: FormGroup<AdvantageCalculatorForm>;

    public get getForm(): FormGroup<AdvantageCalculatorForm> {
        return this.form;
    }

    public initForm(): FormGroup<AdvantageCalculatorForm> {
        const initialInsurant = this.createEmptyInsurant();

        this.form = this.formBuilder.group<AdvantageCalculatorForm>({
            primaryInsurant: initialInsurant,
            additionalInsurants: new FormArray<FormGroup<InsurantGroup>>([]),
        });

        return this.form;
    }

    public addAdditionalInsurant(): void {
        this.form.controls.additionalInsurants.push(this.createEmptyInsurant('additional'));
    }

    public deleteAdditionalInsurant(index: number): void {
        this.form.controls.additionalInsurants.removeAt(index);
    }

    public clearAdditionalInsurants(): void {
        this.form.controls.additionalInsurants = new FormArray<FormGroup<InsurantGroup>>([]);
    }

    public processAdvantages(individualAdvantages: AdvantageCalculatorResultItem[]): void {
        const { primaryInsurant, additionalInsurants } = this.form.controls;

        this.createAdvantageControls(primaryInsurant, individualAdvantages, true);

        additionalInsurants.controls.forEach(additionalInsurant => {
            this.createAdvantageControls(additionalInsurant, individualAdvantages, false);
        });
    }

    private createEmptyInsurant(type: 'primary' | 'additional' = 'primary'): FormGroup<InsurantGroup> {
        const control = this.formBuilder.group<InsurantGroup>({
            name: new FormControl<'' | undefined | null>(undefined),
            gender: new FormControl<'m' | 'f' | 'd' | '' | null>('', [Validators.required]),
            age: new FormControl<number | null>(null, [Validators.required, this.ageValidatorFn]),
            advantages: this.formBuilder.array<FormGroup<AdvantageCalculatorResultItemFormElement>>([]),
            sum: new FormControl<number | null>(0),
        });

        if (type === 'additional') {
            control.controls.name.addValidators([Validators.required, this.nameValidatorFn]);
        }

        return control;
    }

    private createAdvantageControls(
        insurant: FormGroup<InsurantGroup>,
        individualAdvantages: AdvantageCalculatorResultItem[],
        isPrimaryInsurant: boolean,
    ): void {
        const metConditionsInsurant = this.calculateConditions(insurant.controls, isPrimaryInsurant);
        const matchedAdvantages = individualAdvantages.filter(resultItem => resultItem.conditions?.every(value => metConditionsInsurant.includes(value)));
        matchedAdvantages.forEach(advantage => {
            const group = this.formBuilder.group<AdvantageCalculatorResultItemFormElement>({
                advantage: new FormControl<AdvantageCalculatorResultItem>(advantage),
                checked: new FormControl<boolean>(true),
            });
            insurant.controls.advantages.push(group);
        });

        insurant.controls.advantages.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(resultItems => {
            let sum = 0;
            resultItems.forEach(item => {
                if (item.checked && item.advantage?.value) {
                    sum += item.advantage.value;
                }
            });
            insurant.controls.sum.setValue(sum);
        });
    }

    private calculateConditions(insurant: InsurantGroup, isPrimaryInsurant: boolean): Partial<typeof advantageConditions>[number][] {
        const insurantConditions: Partial<typeof advantageConditions>[number][] = ['general'];

        if (isPrimaryInsurant) {
            insurantConditions.push('main-insured-only');
        }

        if (insurant.gender.value !== 'm') {
            insurantConditions.push('female-and-others-only');
        }

        /* eslint-disable @typescript-eslint/no-magic-numbers, sonarjs/no-duplicate-string */
        const age = insurant.age.value;
        if (!age) {
            return insurantConditions;
        }
        if (age <= 2) {
            insurantConditions.push('max-2-years');
        }
        if (age >= 35) {
            insurantConditions.push('min-35-years', 'min-18-years', 'min-6-years', 'min-3-years');
        } else if (age >= 18) {
            insurantConditions.push('min-18-years', 'min-6-years', 'min-3-years');
        } else if (age >= 6) {
            insurantConditions.push('min-6-years', 'min-3-years');
        } else if (age >= 3) {
            insurantConditions.push('min-3-years');
        }
        /* eslint-enable @typescript-eslint/no-magic-numbers, sonarjs/no-duplicate-string */

        return insurantConditions;
    }
}
