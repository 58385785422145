import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServiceToolBaseComponent } from '@big-direkt/service-tools/base';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { AdvantageCalculatorFormStep } from '../models/advantage-calculator-form-step.model';
import { AdvantageCalculatorForm } from '../models/advantage-calculator-form.model';
import { AdvantageCalculatorService } from '../services/advantage-calculator.service';
import { AdvantageCalculatorAdditionalInsurantsStepComponent } from './additional-insurants-step/additional-insurants-step.component';
import { AdvantageCalculatorPrimaryInsurantStepComponent } from './primary-insurant-step/primary-insurant-step.component';
import { AdvantageCalculatorSummaryStepComponent } from './summary-step/summary-step.component';

@Component({
    selector: 'big-service-tool-advantage-calculator',
    standalone: true,
    templateUrl: './advantage-calculator.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        AdvantageCalculatorAdditionalInsurantsStepComponent,
        AdvantageCalculatorPrimaryInsurantStepComponent,
        AdvantageCalculatorSummaryStepComponent,
        FormsModule,
        ReactiveFormsModule,
    ],
    viewProviders: [AdvantageCalculatorService],
    providers: [
        provideTranslationScope('stAdvantageCalculator', /* istanbul ignore next */ async (lang: string, root: string) => import(`../../${root}/${lang}.json`)),
    ],
})
export class AdvantageCalculatorComponent extends ServiceToolBaseComponent<AdvantageCalculatorForm> {
    private readonly advantageCalculatorService = inject(AdvantageCalculatorService);

    public formStep: AdvantageCalculatorFormStep = 'primaryInsurant';

    public constructor() {
        super();
        this.initForm();
    }

    public onPrimaryInsurantSubmit(): void {
        this.setFormStep('additionalInsurants');
    }

    public onPrimaryInsurantSummaryClicked(): void {
        this.setFormStep('summary');
        this.trackStFormSubmitEvent(1);
    }

    public onAdditionalInsurantBackClick(): void {
        const additionalGroup = this.form?.controls.additionalInsurants.at(0);

        // clear additional insurants if they are not filled yet
        if (additionalGroup && !additionalGroup.valid) {
            this.advantageCalculatorService.clearAdditionalInsurants();
        }

        this.setFormStep('primaryInsurant', false);
    }

    public onAdditionalInsurantSummaryClicked(): void {
        this.setFormStep('summary', false);
        const insurantCount = (this.form?.controls.additionalInsurants.length ?? 0) + 1;
        this.trackStFormSubmitEvent(insurantCount);
    }

    public onRecalculateAdvantagesClick(): void {
        this.initForm();
        this.setFormStep('primaryInsurant', false);
    }

    private setFormStep(formStep: AdvantageCalculatorFormStep, withValidation = true): void {
        this.hasBeenSubmitted = false;

        if (!this.form?.controls.primaryInsurant.valid && withValidation) {
            this.hasBeenSubmitted = true;

            return;
        }

        this.formStep = formStep;
    }

    private initForm(): void {
        this.form = this.advantageCalculatorService.initForm();
    }
}

export default AdvantageCalculatorComponent;
